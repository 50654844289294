<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true" size="small">
      <el-form-item label="">
        <el-select v-model="search.status" placeholder="请选择状态" clearable>
          <el-option :key="index" :label="item.title" :value="item.id" v-for="(item,index) in statusList"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入关键词" size="small" clearable>
          <el-button slot="append" class="iv-search" size="small" type="primary" @click="handleSearch">搜索</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%"
        size="mini">
      <el-table-column
          prop="order_sn"
          width="150"
          label="订单号"
          align="center"
          fixed>
        <template slot-scope="scope">
          <span style="cursor: pointer; color: #07d;" @click="showGoods(scope.row)">{{scope.row.order_sn}} <i class="el-icon-search"></i></span>
        </template>
      </el-table-column>
      <el-table-column
          prop="amount"
          width="150"
          label="付款金额"
          align="center">
        <template slot-scope="scope">
          ￥{{scope.row.amount}}
        </template>
      </el-table-column>
      <el-table-column
          prop="user_id"
          min-width="240"
          label="买家信息">
        <template slot-scope="scope">
          <div class="member-box">
            <img :src="scope.row.member_avatar ? scope.row.member_avatar : source_domain + '/images/head.png'" />
            <div>
              <div>{{scope.row.member_name}}（{{scope.row.member_user_id}}）</div>
              <div>{{scope.row.member_mobile}}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="user_id"
        min-width="240"
        label="推客信息">
      <template slot-scope="scope">
        <div class="member-box">
          <img :src="scope.row.guider_avatar ? scope.row.guider_avatar : source_domain + '/images/head.png'" />
          <div>
            <div>{{scope.row.guider_name}}（{{scope.row.guider_user_id}}）</div>
            <div>{{scope.row.guider_mobile}}</div>
          </div>
        </div>
      </template>
    </el-table-column>
      <el-table-column
          prop="commission"
          width="150"
          label="佣金"
          align="center">
        <template slot-scope="scope">
          <span style="cursor: pointer; color: #07d;" @click="showCommission(scope.row)">￥{{scope.row.commission}} <i class="el-icon-search"></i></span>
        </template>
      </el-table-column>
      <el-table-column
          prop="settle_status"
          width="150"
          label="状态"
          align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.pay_status==0">待付款</span>
          <span v-else-if="scope.row.settle_status==0">待结算</span>
          <span v-else-if="scope.row.settle_status==1">已结算</span>
          <span v-else-if="scope.row.settle_status==2">已退款</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="settle_time"
          min-width="150"
          label="结算时间"
          align="center">
        <template slot-scope="scope">
          <span>{{scope.row.settle_time || '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="ftime"
          fixed="right"
          label="创建时间"
          align="center"
          width="140">
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog
        title="订单商品"
        :visible.sync="showGoodsModal"
        :destroy-on-close="true"
        width="700px">
      <el-table
          style="margin-top: -10px"
          v-if="orderInfo"
          :data="orderInfo.goodsList"
          ref="rfTable1"
          size="mini">
        <el-table-column
            prop="goods_name"
            label="商品名称"
            align="left"
            min-width="140">
          <template slot-scope="scope">
            {{scope.row.goods_name}}<span v-if="scope.row.specs_name"> - {{scope.row.specs_name}}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="quantity"
            label="商品数量"
            align="center"
            width="100">
        </el-table-column>
        <el-table-column
            prop="price"
            width="120"
            label="商品总价"
            align="center">
          <template slot-scope="scope">
            ￥{{scope.row.price*scope.row.quantity}}
          </template>
        </el-table-column>
        <el-table-column
            prop="commission"
            width="120"
            label="商品佣金"
            align="center">
          <template slot-scope="scope">
            ￥{{scope.row.commission}}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
        title="订单佣金"
        :visible.sync="showCommissionModal"
        :destroy-on-close="true"
        width="700px">
      <el-table
          style="margin-top: -10px"
          v-if="orderInfo"
          :data="orderInfo.commissionList"
          ref="rfTable1"
          size="mini">
        <el-table-column
            prop="guider_name"
            label="推客"
            align="left"
            min-width="140">
          <template slot-scope="scope">
            <div class="member-box">
              <img :src="scope.row.guider_avatar ? scope.row.guider_avatar : source_domain + '/images/head.png'" />
              <div>
                <div>{{scope.row.guider_name}}（{{scope.row.guider_user_id}}）</div>
                <div>{{scope.row.guider_mobile}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="level"
            label="佣金等级"
            align="center"
            width="100">
        </el-table-column>
        <el-table-column
            prop="commission"
            width="120"
            label="佣金"
            align="center">
          <template slot-scope="scope">
            ￥{{scope.row.commission}}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        status: '',
      },
      tableData: [],
      statusList: [
        {id:1,title:'待付款'},
        {id:2,title:'待结算'},
        {id:3,title:'已结算'},
        {id:4,title:'已退款'},
      ],
      orderInfo: false,
      showGoodsModal: false,
      showCommissionModal: false
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    showGoods(item) {
      this.orderInfo = item
      this.showGoodsModal = true
    },
    showCommission(item) {
      this.orderInfo = item
      this.showCommissionModal = true
    },
    getList() {
      var _this = this
      _this.showLoading()
      var param = {
        page: _this.search.current_page,
        page_size: _this.search.limit,
        keywords: _this.search.keywords,
        status: _this.search.status
      }
      this.$api.distribution.guiderOrderIndex(param, function (res) {
        _this.hideLoading()
        if (res.errcode == 0) {
          _this.tableData = res.data.data
          _this.search.current_page = res.data.current_page
          _this.search.total = res.data.total
        } else {
          _this.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
